import _ from 'lodash'

// constants
import { FORM_FIELD_PREFIX, JSON_FORM_ASSET_ID_PREFIX } from 'constants/assets'
import { jsonFormIdPrefix } from 'constants/formBuilder'

// types
import type { Widget } from 'types/asset'

export const getSortedWidgets = (widgetsConfigs?: Widget[]): Widget[] => {
  return _.sortBy(widgetsConfigs, ['layout.y', 'layout.x'])
}

/** Converts jsonform_4ec0e3a8_3236_44ed_9395_e2df090ec9cf => jsonForm-4ec0e3a8-3236-44ed-9395-e2df090ec9cf */
export const getJsonFormIdFromAssetProfileId = (assetProfileId?: string) => {
  if (
    !assetProfileId ||
    !assetProfileId.startsWith(JSON_FORM_ASSET_ID_PREFIX)
  ) {
    return ''
  }

  return assetProfileId
    .replaceAll('_', '-')
    .replace(JSON_FORM_ASSET_ID_PREFIX, jsonFormIdPrefix)
}

/** Converts fr_wind_speed_g9MYJXdOCHvN5sa0 => wind_speed_g9MYJXdOCHvN5sa0 */
export const getCleanPropertyName = (key: string) =>
  key.startsWith(FORM_FIELD_PREFIX) ? key.replace(FORM_FIELD_PREFIX, '') : key
