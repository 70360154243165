import _ from 'lodash'
import keymirror from 'keymirror'

// constants
import { WIDGET_TYPES } from 'constants/widget'
import { LAYER_TYPES } from 'constants/map'

export const DEFAULT_ASSET_PROFILE_NAME = 'site'

export const ASSET_PROFILE_WIDGET_CATEGORIES = keymirror({
  data: null,
  domain: null,
  layout: null,
})

export const ASSET_PROFILE_WIDGET_CONFIGS = _.keyBy(
  [
    {
      name: WIDGET_TYPES.table,
      widgetName: 'AssetPropertiesTableWidget',
      icon: 'AiOutlineTable',
      category: ASSET_PROFILE_WIDGET_CATEGORIES.data,
    },
    {
      name: WIDGET_TYPES.text,
      widgetName: 'AssetTextWidget',
      icon: 'MdTextFields',
      category: ASSET_PROFILE_WIDGET_CATEGORIES.data,
    },
    {
      name: WIDGET_TYPES.image,
      widgetName: 'AssetImageGalleryWidget',
      icon: 'BsImages',
      category: ASSET_PROFILE_WIDGET_CATEGORIES.data,
    },
    {
      name: WIDGET_TYPES.gauge,
      widgetName: 'AssetGaugeWidget',
      icon: 'AiOutlineDashboard',
      category: ASSET_PROFILE_WIDGET_CATEGORIES.data,
    },
    {
      name: WIDGET_TYPES.scatter,
      widgetName: 'AssetScatterWidget',
      icon: 'AiOutlineDotChart',
      category: ASSET_PROFILE_WIDGET_CATEGORIES.data,
    },
    {
      name: WIDGET_TYPES.bar,
      widgetName: 'AssetBarWidget',
      icon: 'AiOutlineBarChart',
      category: ASSET_PROFILE_WIDGET_CATEGORIES.data,
    },
    {
      name: WIDGET_TYPES.line,
      widgetName: 'AssetLineWidget',
      icon: 'AiOutlineLineChart',
      category: ASSET_PROFILE_WIDGET_CATEGORIES.data,
    },
    {
      name: 'issues',
      widgetName: 'AssetIssuesListWidget',
      icon: 'AiOutlineWarning',
      category: ASSET_PROFILE_WIDGET_CATEGORIES.domain,
      defaultName: 'Issues',
    },
    {
      name: 'divider',
      widgetName: 'AssetWidgetDivider',
      icon: 'FiDivideSquare',
      category: ASSET_PROFILE_WIDGET_CATEGORIES.layout,
      defaultName: '',
      enablePanel: false,
    },
  ],
  'widgetName'
)

export const ASSET_PROFILE_WIDGET_TYPES = _.keys(ASSET_PROFILE_WIDGET_CONFIGS)

export const WEB_GRID_MARGIN_SIZE = 20
export const WEB_GRID_ROW_SIZE = 10

export const PRINTABLE_GRID_MARGIN_SIZE = 5
export const PRINTABLE_GRID_ROW_SIZE = 22

export const ASSET_PROFILE_GRID_LAYOUT_PROPS = {
  cols: 12,
  margin: [WEB_GRID_MARGIN_SIZE, WEB_GRID_MARGIN_SIZE] as [number, number],
  rowHeight: WEB_GRID_ROW_SIZE,
  draggableCancel: '.nonDraggable',
}

export const ASSET_PROFILE_PRINTABLE_LAYOUT = {
  margin: [PRINTABLE_GRID_MARGIN_SIZE, PRINTABLE_GRID_MARGIN_SIZE] as [
    number,
    number
  ],
  isDroppable: false,
  isDraggable: false,

  // https://github.com/react-grid-layout/react-grid-layout/issues/233
  rowHeight: PRINTABLE_GRID_ROW_SIZE,
}

export const DEFAULT_ASSET_WIDGET_DIVIDER_STYLE = {
  colour: [255, 255, 255],
  height: 2,
  opacity: 0.25,
}

export const ASSET_PROFILE_DRAG_WIDGET_KEY = 'assetProfileDragWidget'

export const DEFAULT_GRID_LAYOUT_ITEM_STYLE = {
  backgroundColor: 'rgba(255, 255, 255, 0.07)',
  color: '#fff',
}

export const DEFAULT_ASSET_PROFILE_VIEW_CONFIGS_ID = 'default'

export const ASSET_PROFILE_MEDIA_TYPES = keymirror({
  WEB: null,
  MOBILE: null,
  PRINTABLE: null,
})

export const ASSET_PROFILE_RELATIONSHIP_TYPES = keymirror({
  ONE: null,
  MANY: null,
})

export const ASSET_PROFILE_IDS = keymirror({
  site: null,
  worker: null,
})

export const DEFAULT_ASSET_LAYER_TYPE = LAYER_TYPES.upGeojson

export const PROPERTY_TABLE_COLUMNS = [
  {
    accessor: 'propertyKey',
    Header: 'Property',
    width: '40%',
  },
  { accessor: 'propertyValue', Header: '', width: '60%' },
]

export const PRINT_PDF = '?print'

export const ASSET_MAP_STYLE = 'assetMapStyle'

export const JSON_FORM_ASSET_ID_PREFIX = 'jsonform'
export const FORM_FIELD_PREFIX = 'fr_'
